
const key = 'BEACONS_FINGERPRINT'

// Generate a random UUID (version 4)
const generateUUID = (): string => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
})


export const getClientFingerprint = (): string => {
    // Check if the UUID is already in local storage
    const storedUUID = localStorage.getItem(key) ?? getCookie(key);
    
    if (storedUUID) {
        return storedUUID;
    }

    // Generate a new UUID
    const uuid = generateUUID();

    // Store the new UUID in local storage
    localStorage.setItem(key, uuid);
    setCookie(key, uuid)


    return uuid;
}

const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
}

const getCookie = (name: string): string | null => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

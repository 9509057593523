import React, { useCallback, useEffect, useState } from 'react';
import Message from './message';
import { AnyMessageProps, FullMessageProps, HalfMessageProps, MessageRowSizes } from '../types';
import { useData } from '../contexts/DataContext';

interface SignData {
  messages: AnyMessageProps[],
  messageIndex: number,
}


interface SignMessageProps {
  onlyHalfMessages: boolean;
  height: MessageRowSizes;
}

const SignMessage: React.FC<SignMessageProps> = ({ onlyHalfMessages, height }) => {
  const { messages } = useData()
  const filteredMessages = filterMessages(messages.current,onlyHalfMessages);

  const [signData, setSignData] = useState<SignData>({
    messages: filteredMessages,
    messageIndex: filteredMessages.length>0 ? 0 : -1
  })

  //rotate function
  const advance = useCallback(()=>{
    setSignData(prev => {
      const filteredMessages = filterMessages(messages.current,onlyHalfMessages);

      //If the sign has completely cycled through it's messages
      const isAtEndOfCycle = prev.messageIndex + 1 >= prev.messages.length

      //update with current data and reset cycle
      if(isAtEndOfCycle)
        return{
          messages:filteredMessages,
          messageIndex:filteredMessages.length>0 ? 0 : -1
        }
      
      return {
        messages: prev.messages,
        messageIndex: isAtEndOfCycle ? 0 : prev.messageIndex + 1
      }
    })
  },[onlyHalfMessages])

  // Start rotating when the component mounts
  useEffect(() => {
    const rotationTime = import.meta.env.VITE_SCREEN_ROTATION || 1000;
    const timer = setInterval(advance, rotationTime);

    // Cleanup function to stop the interval when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, [advance]);

  const message = signData.messages[signData.messageIndex]
  // full messages on the half screen are... different
  if(message && message.type==='FULL'){
    const wierdFullMessage:HalfMessageProps = {
      type: 'HALF',
      details: message.headline ?? message.details
    }
    return <Message message={wierdFullMessage} height={height}/>
  }

  if(height===MessageRowSizes.FULL && message && message.type==='HALF'){
    const wierdJumboHalfMessage:FullMessageProps = {
      type: 'FULL',
      details: message.details
    }
    return <Message message={wierdJumboHalfMessage} height={height}/>
  }

  return <Message message={message} height={height}/>
};

export default SignMessage;



// guarantee no comingling of HALF and FULL messages
// explicitly check for HALF and FULL in case a new type is added
const filterMessages = (messages: AnyMessageProps[], onlyHalfMessages: boolean)=>{
  // if the half message sign is set to HALF message mode, then only show half messages
  if(onlyHalfMessages)
    return messages.filter(m => m.type === 'HALF')

  // if there is a full message return that
  const fullMessage = messages.find(m => m.type === 'FULL')
  if(fullMessage)
    return [fullMessage];

  // otherwise return half messages
  return messages.filter(m => m.type === 'HALF')
}
import { AnyMessageProps, SignHeight, SignMode, Train } from "../types";

export const determineSignMode = (
  messages: AnyMessageProps[],
  trains: Train[],
  height: SignHeight
): SignMode => {
  const hasTrains = trains.length > 0
  const hasFullMessages = messages.filter(m=>m.type==='FULL').length > 0
  const hasHalfMessages = messages.filter(m=>m.type==='HALF').length > 0

  if(height===SignHeight.FULL){
    if (hasFullMessages)
      return SignMode.FullMessage;
    if (!hasTrains && hasHalfMessages)
      return SignMode.JumboHalfMessage;
    if (hasHalfMessages)
      return  SignMode.FullMessageTrains
    if (hasTrains) {
      return SignMode.FullTrains
    }
  }

  if(height===SignHeight.HALF){
    if (hasFullMessages || (hasHalfMessages && !hasTrains))
      return SignMode.HalfMessage;
    if (hasTrains) {
      return SignMode.HalfTrains
    }
  }


  return SignMode.None;
};

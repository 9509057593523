import { ReactNode } from "react";

export interface Train {
    arrow?: number,
    route: string,
    primary: string,
    secondary?: string,
    stop_status: string,
    est_time: number,
    delayed: boolean,
    interval:string
}
export interface SignTrain extends Train {
    index: number,
    isArriving: boolean,
    etaMinutes: number
}

export type AnyMessageProps = HalfMessageProps | FullMessageProps
export interface HalfMessageProps {
    type: "HALF",
    details: string
}

export interface FullMessageProps {
    type: "FULL",
    headline?: string,
    details: string
}


export interface LineProps {
    line: string;
}


export enum SignMode {
    Loading=1,
    Fallback=2,
    None=3,
    FullMessage=4,
    HalfMessage=5,
    JumboHalfMessage=6,
    FullTrains=7,
    HalfTrains=8,
    FullMessageTrains=9
}
export type SignModeComponentMap = {
    [K in SignMode]: ReactNode
  }

export enum SignHeight {
    FULL=480,
    HALF=240,
    Unknown=-1
}

export enum MessageRowSizes {
    FULL = 'height-480',
    HALF = 'height-240',
    HALF_MARGIN = 'height-230',
}
export enum SingleTrainRowSizes {
    HALF = 'height-240',
    HALF_MARGIN = 'height-230',
}

export enum MetricName {
    BrowserStart='onBrowserStartup',
    Mode='Mode',
    TimeSyncMilli='TimeSyncMilli',
    StaleSeconds='StaleSeconds',
    HttpStatusCode='HttpStatusCode',
    HttpStatus='HttpStatus',
    HttpResponseMilli='HttpResponseMilli',
    HttpResponseKB='HttpResponseKB',
    HttpCode2XX='HttpCode2XX',
    HttpCode4XX='HttpCode4XX',
    HttpCode5XX='HttpCode5XX',
    HttpCodeOther='HttpCodeOther',
    HttpErrorNetwork='HttpErrorNetwork',
    HttpErrorTimeout='HttpErrorTimeout',
    HttpErrorNoCode='HttpErrorNoCode',
    PollSuccess='PollSuccess',
    PollFailure='PollFailure',
    TrainCount='TrainCount'
}

export enum HttpStatus {
    _2XX=1,
    Network=2,
    Timeout=3,
    _4XX=4,
    _5XX=5,
    OtherCode=6,
    No_Code=7
}
import { Icon, isValidIcon } from "../components/icon";

export const routeBulletIconographer = (
    input: string | undefined
): React.ReactNode => {
    // Define the pattern to search for text inside brackets
    const pattern = /\[([^\]]+)\]/g;

    if (input === undefined || input.length === 0) return '';

    // Function to handle the replacements
    const renderText = () => {
        const parts: React.ReactNode[] = [];
        let lastIndex = 0;
        let lastWasIcon = false; // To track if the last part was an icon

        input.replace(pattern, (match, p1, offset) => {
            // Get the text before the current match
            let textBeforeMatch = input.slice(lastIndex, offset);

            // Check if there are multiple spaces between icons and normalize them
            if (lastWasIcon && textBeforeMatch.trim().length === 0) {
                textBeforeMatch = ' ';
            }
            // Add normalized text before the match, if any
            if (textBeforeMatch) {
                // If text follows an icon and starts with an alpha character, add a space before the text
                if (lastWasIcon && /^[a-zA-Z]/.test(textBeforeMatch)) {
                    parts.push(<span key={`space-${lastIndex}`} className='space'> </span>);
                }

                parts.push(<span key={`text-${lastIndex}`} className={textBeforeMatch === ' ' ? 'space' : 'text'}>{textBeforeMatch}</span>);
                lastWasIcon = false; // Reset flag since text is not an icon
            }

            // Get the replacement React Node or use the original match if no replacement is found        
            if (isValidIcon(p1)) {
                // Add a space before the new icon if the last was also an icon
                if (lastWasIcon) {
                    parts.push(<span key={`space-${offset}`} className='space'> </span>);
                }

                // Check if the last character of the text before the match is an alpha character
                if (!lastWasIcon && /[a-zA-Z]$/.test(textBeforeMatch)) {
                    parts.push(<span key={`space-${offset}`} className='space'> </span>);
                }

                const replacement = <Icon key={`icon-${offset}`} line={p1} />;
                parts.push(replacement);
                lastWasIcon = true; // Set flag since this is an icon
            } else {
                parts.push(<span key={`text-${offset}`} className='text'>{match}</span>); // If no replacement found, keep the original match
                lastWasIcon = false; // Reset flag since this is not an icon
            }

            // Update the last index past this match
            lastIndex = offset + match.length;
            return match; // This return is necessary for types, but not used
        });

        // Add any remaining text after the last match
        if (lastIndex < input.length) {
            let remainingText = input.slice(lastIndex);

            // If text follows an icon and starts with an alpha character, add a space before the text
            if (lastWasIcon && /^[a-zA-Z]/.test(remainingText)) {
                parts.push(<span key={`space-${lastIndex}`} className='space'> </span>);
            }

            parts.push(<span key={`text-${lastIndex}`} className='text'>{remainingText}</span>);
        }

        return parts;
    };

    return <>{renderText()}</>;
};

import React, { useCallback, useEffect, useState } from 'react';
import Message from './message';
import { AnyMessageProps, MessageRowSizes } from '../types';
import { useData } from '../contexts/DataContext';

interface SignData {
  message: AnyMessageProps | undefined
}

const SignMessage: React.FC = () => {
  const { messages } = useData()
  const [signData, setSignData] = useState<SignData>({
    message: messages.current.find(m => m.type === 'FULL')
  })

  //rotate function
  const advance = useCallback(()=>{      
    setSignData(() => ({
      message: messages.current.find(m => m.type === 'FULL')
    }))
  },[])

  // Start rotating when the component mounts
  useEffect(() => {
    const rotationTime = import.meta.env.VITE_SCREEN_ROTATION || 1000;
    const timer = setInterval(advance, rotationTime);

    // Cleanup function to stop the interval when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, [advance]);

  return <Message message={signData.message} height={MessageRowSizes.FULL}/>
};

export default SignMessage;